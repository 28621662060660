export const roles = [
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'guest',
        label: 'Guest',
    }
];
