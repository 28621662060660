export enum EntityKey {
    ELEVATORS = 'elevators',
    MAINTENANCES = 'maintenances',
    TRIPS = 'trips',
    FLOOR_ACTIVITY = 'floor-activity',
    ELEVATOR_COORDINATES = 'elevator-coordinates',
    ELEVATOR_STATES = 'elevator-states',
    ELEVATOR_STATE = 'elevator-state',
    DOCUMENT_INVOICES = 'document-invoices',
    DOCUMENT_CONTRACTS = 'document-contracts',
    DOCUMENT_OTHER = 'document-other',
    DOCUMENT_QUOTATIONS = 'document-quotations',
    DOCUMENT_ASSESSMENT_REPORTS = 'document-assessment-reports',
    DOCUMENTS = 'documents',
    DOCUMENT_WORKORDER = 'document-workorder',
    IOT_CONFIG = 'iot-config',
    INCIDENTS = 'incidents',
    SUPPORT_REQUEST = 'support-request',
    LOGIN = 'login',
    CLOSED_INCIDENTS = 'closed-incidents',
    TEAMS = 'teams',
}

export enum ApiReducerKey {
    LIST = 'list',
    UPDATE = 'update',
    AVERAGE_LIST = 'average-list',
    TOTAL_LIST = 'total-list',
    DETAIL = 'detail',
    DOWNLOAD = 'download',
    LOGIN = 'login',
    SUPPORT_REQUEST = 'support-request',
    UPLOAD = 'upload',
    SINGLE = 'single',
    INCIDENT = 'incident',
    SUPPORT_CLOSED = 'closedSupports',
    SUPPORT_OPENED = 'openSupports',
    SUPPORT_CATEGORY = 'categories',
    ACTIVE = 'active',
    RESOLVED = 'resolved',
    PICKLIST = 'picklist',
    REJECTED = 'rejected',
    UNVALIDATED = 'unvalidated',
    CREATE_USERS = 'create-users',
    CHANGE_ROLE = 'change-role',
    DEACTIVATE_USER = 'deactivate-user',
    EDIT_NOTIFICATION = 'edit-notification',
    USERS='users',
    NOTIFICATIONS = 'notifications'
}
