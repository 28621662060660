import { basicResetReducer } from '@ackee/redux-utils';
import { AUTH_SESSION_END } from '@ackee/petrus';
import { combineReducers } from 'redux';

import { EntityKey } from 'constants/index';
import { entityReducer as elevators } from '../../modules/elevators';
import { entityReducer as maintenances } from '../../modules/maintenances';
import { entityReducer as incidents } from '../../modules/incidents';
import { entityReducer as supports } from '../../modules/support';
import { entityReducer as trips } from '../../modules/trips';
import { entityReducer as elevatorStates } from '../../modules/elevator-states';
import { entityReducer as elevatorCoordinates } from '../../modules/elevator-coordinates';
import { entityReducer as floorActivity } from '../../modules/elevator-floor-activity';
import { entityReducer as elevatorState } from '../../modules/elevator-state';
import { entityReducer as invoices } from '../../modules/documents-invoices';
import { entityReducer as documents } from '../../modules/documents';
import { entityReducer as contracts } from '../../modules/documents-contracts';
import { entityReducer as otherDocuments } from '../../modules/other-documents';
import { entityReducer as workorderDocuments } from '../../modules/workorder-documents';
import { entityReducer as quotations } from '../../modules/documents-quotations';
import { entityReducer as assessmentReports } from '../../modules/documents-assessment-reports';
import { entityReducer as iotConfig } from '../../modules/config';
import { entityReducer as closedIncidents } from '../../modules/closed-incidents';
import { entityReducer as fetchUsers } from '../../modules/teams';
const resetReducer = reducer => basicResetReducer(reducer, AUTH_SESSION_END);

export default combineReducers({
    [EntityKey.ELEVATORS]: resetReducer(elevators),
    [EntityKey.INCIDENTS]: resetReducer(incidents),
    [EntityKey.TRIPS]: resetReducer(trips),
    [EntityKey.ELEVATOR_STATES]: resetReducer(elevatorStates),
    [EntityKey.ELEVATOR_COORDINATES]: elevatorCoordinates,
    [EntityKey.FLOOR_ACTIVITY]: resetReducer(floorActivity),
    [EntityKey.ELEVATOR_STATE]: resetReducer(elevatorState),
    [EntityKey.DOCUMENT_INVOICES]: resetReducer(invoices),
    [EntityKey.DOCUMENT_CONTRACTS]: resetReducer(contracts),
    [EntityKey.DOCUMENT_OTHER]: resetReducer(otherDocuments),
    [EntityKey.DOCUMENT_WORKORDER]: resetReducer(workorderDocuments),
    [EntityKey.DOCUMENT_QUOTATIONS]: resetReducer(quotations),
    [EntityKey.DOCUMENT_ASSESSMENT_REPORTS]: resetReducer(assessmentReports),
    [EntityKey.DOCUMENTS]: resetReducer(documents),
    [EntityKey.IOT_CONFIG]: resetReducer(iotConfig),
    [EntityKey.CLOSED_INCIDENTS] : resetReducer(closedIncidents),
    [EntityKey.MAINTENANCES] : resetReducer(maintenances),
    [EntityKey.SUPPORT_REQUEST] : resetReducer(supports),
    [EntityKey.TEAMS] : resetReducer(fetchUsers),
});
