import { Form, FormProps, Image, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import userSvg from 'assets/images/user.svg';
import * as felaRules from './ChangeRole.styles';
import { useFelaEnhanced } from 'hooks';
import { useIntl } from 'react-intl';
import { FloatingLabel, Select } from 'modules/form';
import { roles } from 'modules/teams/constants';
import TeamDialogFooter from '../TeamDialogFooter';
import { useChangeRole } from 'modules/entities/modules/teams/hooks';
import { useNotifications } from 'modules/ui';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';
import { useSetUser } from 'modules/entities/modules/teams/hooks/useSetUser';

const ChangeRole = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const { setState } = useTeamDialog();
    const { api, handleSubmit, resetAction } = useChangeRole();
    const notifications = useNotifications();
    const [form] = Form.useForm()
    const { api: user } = useSetUser()
    const [role, setRole] = useState(user.role);
    const onFinish: FormProps<any>['onFinish'] = (values) => {
        handleSubmit({
            role: values.role,
            email: user.email,
            userId: user.userId
            
        });
    };

    useEffect(() => {
        setRole(user.role)
    }, [user.role])

    useEffect(() => {
        let main = 'invite.change.role.request.success.title';
        let description = 'invite.change.role.request.success.description';


        if (api.success) {
            notifications.success({
                message: intl.formatMessage({
                    id: main,
                }),
                description: intl.formatMessage({
                    id: description,
                }),
            });

            form.resetFields();
            setState(null);
            resetAction()
        }

        if (api.error) {
            notifications.error({
                message: intl.formatMessage({
                    id: api.error,
                }),
            });
        }
    }, [api, intl, notifications, setState, form, resetAction]);

    return (
        <div className={styles.changeRole}>
            <div className={styles.changeRoleHeading}>
                <Image src={userSvg} alt="user" preview={false} />
                <Typography.Title level={4} className={styles.title}>
                    {intl.formatMessage(
                        {
                            id: 'teams.change.role',
                        },
                        {
                            name: user.name,
                        },
                    )}
                </Typography.Title>
            </div>
            <Typography.Text className={styles.text}>
                {intl.formatMessage({
                    id: 'teams.change.role.select',
                })}
            </Typography.Text>
            <Form onFinish={onFinish} initialValues={{
                role
            }}>
                <FloatingLabel
                    htmlFor="role"
                    floating={Boolean(role)}
                    label={intl.formatMessage({
                        id: 'teams.role.type.label',
                    })}
                    fontSize="0.8rem !important"
                >
                    {({ onFocus, onBlur }) => (
                        <Form.Item
                            name="role"
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'form.validation.required.role' }),
                                },
                            ]}
                        >
                            <Select
                                id="role"
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onChange={value => setRole(value)}
                                options={roles}
                                placeholder={intl.formatMessage({ id: 'teams.role.type.label' })}
                                fullWidth
                                floatingLabel
                                data-testid="role"
                                defaultValue={role}
                            />
                        </Form.Item>
                    )}
                </FloatingLabel>
                {role && (
                    <Typography.Text className={styles.text}>
                        {role === 'admin' &&
                            intl.formatMessage({
                                id: 'teams.change.role.admin.select',
                            })}
                    </Typography.Text>
                )}
                <TeamDialogFooter />
            </Form>
        </div>
    );
};

export default ChangeRole;
