import type { TRule } from 'fela';

export const inviteUser: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
});

export const team: TRule = () => ({
    display: 'grid',
    gridTemplateColumns: '5fr 5fr 1fr 1fr',
    gridTemplateRows: '48px',
    gap: '20px',
});

export const inviteUserHeading: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const title: TRule = () => ({
    marginBottom: '0 !important',
    padding: 0,
    fontSize: '24px',
    marginLeft: '0.6rem',
});

export const text: TRule = () => ({
    color: '#8F8F8F',
    fontSize: '14px',

    '> strong': {
        color: '#262626',
    },
});

export const input: TRule = () => ({
    height: '48px',
    paddingTop: '20px',
});

export const form: TRule = () => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
});

export const button: TRule = () => ({
    background: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const learnMore: TRule = () => ({
    color: '#262626',
    fontSize: '14px',

    '> a': {
        textDecoration: 'underline',
    },
});
