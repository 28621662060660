import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.TEAMS,
});

export const fetchUsersTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_USERS_' }),
});

export const fetchUserNotificationsTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_USER_NOTIFICATIONS_' }),
});
