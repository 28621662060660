import { Input, Select } from 'modules/form';
import { TableSearch, useTableSearch } from 'modules/table';
import { roles } from 'modules/teams/constants';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const TeamsSearchPanel = () => {
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch({});

    const intl = useIntl();
    return (
        <TableSearch
            fields={[
                {
                    label: <FormattedMessage id={`teams.search.name`} />,
                    labelFor: 'name',
                    renderer: (
                        <Input
                            id="name"
                            data-testid="name"
                            value={value['name']}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    name: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`teams.search.email`} />,
                    labelFor: 'email',
                    renderer: (
                        <Input
                            id="email"
                            data-testid="email"
                            value={value['email']}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    email: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`teams.search.role`} />,
                    labelFor: 'role',
                    renderer: (
                        <Select
                            id="type"
                            value={value['role']}
                            fullWidth
                            onChange={role =>
                                handleValue({
                                    role: role,
                                })
                            }
                            options={roles}
                            placeholder={intl.formatMessage({
                                id: 'page.incidents.table.completion.dialog.select.component',
                            })}
                        />
                    ),
                },
            ]}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 8, xxl: 5 }}
        />
    );
};

export default TeamsSearchPanel;
