import { merge } from 'lodash';
import { isEnvDevelopment } from 'constants/index';

const envConfig = require(`./config.${process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV}.js`).default;

const defaults = {
    appName: process.env.REACT_APP_NAME,
    devTools: isEnvDevelopment,
    sentry: {
        dsn: 'https://70c23dfe6523430e838c755a10cb478c@o367752.ingest.sentry.io/5715799',
    },
    airtime: {
        url: 'https://airtimeux.com/airtime.js',
        token: 'mdbdxi3h9qishn33',
    },
    api: {
        signIn: '/v1/auth/sign-in',
        me: '/v1/users/me',
        refresh: '/v1/auth/refresh',
        elevators: '/v1/elevators',
        maintenances: '/v1/maintenances',
        activeIncidents: '/v1/active-incidents',
        elevator: '/v1/elevators/:id',
        averageTrips: '/v1/elevators/:id/average-trips',
        elevatorState: '/v1/elevators/:id/state',
        totalTrips: '/v1/elevators/:id/total-trips',
        elevatorStates: '/v1/elevators/:id/states',
        floorActivity: '/v1/elevators/:id/floor-activity',
        documentInvoices: '/v1/customer-documents/invoices',
        documentContracts: '/v1/customer-documents/contracts',
        documentQuotations: '/v1/customer-documents/quotations',
        documentAssessmentReports: '/v1/customer-documents/elevator-assessments',
        downloadDocuments: '/v1/customer-documents/download',
        otherDocuments: '/v1/customer-documents/others',
        workorderDocuments: '/v1/workorder-documents',
        uploadDocuments: '/v1/:service/upload/:type',
        salesforceRegister: '/v1/users/salesforce-register',
        subscribeFake: '/v1/emails/fake-door-page-info',
        activeIncident: '/v1/active-incident/:id',
        iotConfig: '/v1/config',
        incidents: '/v1/incidents',
        closedIncidents: '/v1/closed-incidents/:id',
        supportRequest: '/v1/supports',
        support: '/v1/support-requests',
        users: '/v1/users',
    },
    routes: {
        home: '/',
        login: '/login',
        registration: '/registration',
        createPassword: '/create-password',
        forgotPassword: '/forgot-password/:step',
        elevators: '/elevators',
        elevatorDetail: '/elevators/:id',
        elevatorHistory: '/elevators/:id/history',
        documents: '/documents',
        supportRequest: '/support-request',
        support: '/support',
        documentsUpload: '/documents/upload',
        invoices: '/documents/invoices',
        contracts: '/documents/contracts',
        riskAssessments: '/documents/risk-assessment-reports',
        emergencyPlans: '/documents/emergency-rescue-plans',
        supportOpen: '/supports/open',
        supportClosed: '/supports/closed',
        conditionReports: '/documents/condition-reports',
        quotations: '/documents/quotations',
        assessmentReports: '/documents/assessment-reports',
        otherDocuments: '/documents/other',
        uploadContract: '/upload-contract',
        uploadQuotation: '/upload-quotation',
        uploadZUES: '/upload-zues',
        uploadZUESDeficiency: '/upload-zues-deficiency',
        operatorObligations: '/operator-obligations',
        maintenanceAnalysis: '/maintenance-analysis',
        incidents: '/incidents',
        incident: '/incident/:id',
        incidentsActive: '/incidents/active',
        incidentsResolved: '/incidents/resolved',
        browserIssues: '/browser-issues',
        workOrders: '/work-orders',
        teams: '/teams',
    },
    aws: {
        amplify: {
            Auth: {
                mandatorySignIn: true,
            },
        },
    },
    analytics: {
        trackingId: 'G-GMM2DLGZKZ',
    },
    tables: {
        defaultPageSize: 10,
    },
    currency: 'EUR',
};

export default merge(defaults, envConfig);
