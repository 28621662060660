import { combineReducers } from 'redux';

import { EntityKey } from 'constants/index';
import { apiReducer as elevators } from '../../modules/elevators';
import { apiReducer as maintenances } from '../../modules/maintenances';
import { apiReducer as incidents } from '../../modules/incidents';
import { apiReducer as trips } from '../../modules/trips';
import { apiReducer as elevatorStates } from '../../modules/elevator-states';
import { apiReducer as floorActivity } from '../../modules/elevator-floor-activity';
import { apiReducer as elevatorState } from '../../modules/elevator-state';
import { apiReducer as invoices } from '../../modules/documents-invoices';
import { apiReducer as documents } from '../../modules/documents';
import { apiReducer as contracts } from '../../modules/documents-contracts';
import { apiReducer as otherDocuments } from '../../modules/other-documents';
import { apiReducer as workorderDocuments } from '../../modules/workorder-documents';
import { apiReducer as quotations } from '../../modules/documents-quotations';
import { apiReducer as assessmentReports } from '../../modules/documents-assessment-reports';
import { apiReducer as iotConfig } from '../../modules/config';
import { apiReducer as supportRequest } from '../../modules/support';
import { apiReducer as closedIncidents } from '../../modules/closed-incidents';
import { apiReducer as teams } from '../../modules/teams';

export default combineReducers({
    [EntityKey.ELEVATORS]: elevators,
    [EntityKey.INCIDENTS]: incidents,
    [EntityKey.CLOSED_INCIDENTS]: closedIncidents,
    [EntityKey.TRIPS]: trips,
    [EntityKey.ELEVATOR_STATES]: elevatorStates,
    [EntityKey.FLOOR_ACTIVITY]: floorActivity,
    [EntityKey.ELEVATOR_STATE]: elevatorState,
    [EntityKey.DOCUMENT_INVOICES]: invoices,
    [EntityKey.DOCUMENT_CONTRACTS]: contracts,
    [EntityKey.DOCUMENT_OTHER]: otherDocuments,
    [EntityKey.DOCUMENTS]: documents,
    [EntityKey.DOCUMENT_WORKORDER]: workorderDocuments,
    [EntityKey.DOCUMENT_QUOTATIONS]: quotations,
    [EntityKey.DOCUMENT_ASSESSMENT_REPORTS]: assessmentReports,
    [EntityKey.IOT_CONFIG]: iotConfig,
    [EntityKey.SUPPORT_REQUEST]: supportRequest,
    [EntityKey.MAINTENANCES]: maintenances,
    [EntityKey.TEAMS]: teams,
});
