import { all } from 'redux-saga/effects';

import { sendInviteRequest } from './sendInviteRequest';
import { sendDeactivateActivateUserRequest } from './sendDeactivateActivateUserRequest';
import { sendChangeRoleRequest } from './sendChangeRoleRequest';
import { sendEditNotificationRequest } from './editNotificationRequest';
import fetchUsersRequest  from './fetchUsersRequest';

export default function* () {
    yield all([
        sendInviteRequest(),
        sendDeactivateActivateUserRequest(),
        sendChangeRoleRequest(),
        sendEditNotificationRequest(),
        fetchUsersRequest()
    ]);
}
